import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { Header } from "../components/layout/header";
import MainLayout from "../components/layout/main-layout";
import OurHistory from "../components/history/our-history";
import OurProducts from "../components/products/our-products";
import { StyledLink } from "../styles/typography";
import MuteIcon from "../images/volume.png";
import "react-notifications/lib/notifications.css";

const KnowMore = styled(StyledLink)`
  width: 120px;
  text-align: center;
`;

const Title = styled.h3`
  margin: 10px 0;
  font-size: 24px;
  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
`;

const Subtitle = styled.h3`
  font-size: 20px;
`;

const Novidades = styled.h2`
  font-family: "DM Sans", sans-serif;
  margin: 75px 0;
  font-size: 21px;
  text-transform: uppercase;
`;

const Video = styled.video`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(70%);
`;

const RelativeContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const MuteControlButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #dd614a;
  border: 0;
  cursor: pointer;
  border-radius: 6px;
  width: 50px;
  height: 50px;
  outline: none;
`;

const MuteImage = styled.img`
  filter: invert(1);
  opacity: 1;

  ${(props) =>
    props.muted &&
    `
    filter: invert(0);
    opacity: 0.2;
  `}
`;

const IndexPage = () => {
  const [muted, setMuted] = useState(true);
  const data = useStaticQuery(graphql`
    {
      markdownRemark(fields: { slug: { eq: "/receitas/homepage/" } }) {
        id
        frontmatter {
          buttonText
          buttonUrl
          description
          subtitle
          title
        }
      }
    }
  `);

  const { title, subtitle, description, buttonText, buttonUrl } =
    data.markdownRemark.frontmatter;

  useEffect(() => {
    if (window.location.search.includes("action=contacto")) {
      NotificationManager.success("Contacto submetido com sucesso", "Sucesso");
    }

    if (window.location.search.includes("action=encomendas")) {
      NotificationManager.success("Encomenda efetuada com sucesso", "Sucesso");
    }
  }, []);

  return (
    <Layout>
      <NotificationContainer />
      <SEO title="Cogumelos de Argoncilhe" keywords={[]} />

      <RelativeContainer>
        <Video autoPlay loop muted={muted} playsInline>
          <source src="/intro_1080.webm" type="video/webm" />
          <source src="/intro_1080.ogv" type="video/ogg" />
          <source src="/intro_1080.mp4" type="video/mp4" />
        </Video>
        <MuteControlButton onClick={() => setMuted(!muted)}>
          <MuteImage src={MuteIcon} alt="Mute" muted={muted} />
        </MuteControlButton>
        <MainLayout size="100vh">
          <Header>
            {title && <Novidades>{title}</Novidades>}
            {subtitle && <Title>{subtitle}</Title>}
            {description && <Subtitle>{description}</Subtitle>}
            {buttonText && buttonUrl && (
              <KnowMore to={buttonUrl}>{buttonText}</KnowMore>
            )}
          </Header>
        </MainLayout>
      </RelativeContainer>

      <MainLayout>
        <OurHistory />
      </MainLayout>

      <OurProducts />
    </Layout>
  );
};

export default IndexPage;
