import React from "react";

const HistoryText = () => (
  <>
    Brotámos em 2016 quando os nossos fundadores, Sónia Lei e Nuno Pereira,
    decidiram ir além do agradável paladar do cogumelo, para se especializarem
    na sua produção. A nossa missão é transformar a paixão pelo que fazemos num
    cogumelo único, <b>100% biológico.</b> Para isso implementámos técnicas de
    produção <b>ecológica</b> e <b>sustentável</b>, assentes na{" "}
    <b>valorização</b> da matéria orgânica e que promovem a{" "}
    <b>economia circular.</b>
  </>
);

export default HistoryText;
