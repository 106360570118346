import React from "react";
import styled from "@emotion/styled";
import { H1, StyledLink } from "../../styles/typography";
import image from "../../images/our-history.jpg";
import HistoryText from "./history-text";

const OurHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
  padding: 75px 0;
  font-size: 21px;
  letter-spacing: 0.5px;
  margin: 0 20px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
    margin: 0 80px;
  }

  @media screen and (min-width: 1400px) {
    margin: 0;
  }
`;

const Title = styled(H1)`
  color: var(--color2);
`;

const Description = styled.div`
  color: var(--color2);
`;

const Img = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const KnowMoreButton = styled(StyledLink)`
  margin-top: 25px;
`;

const OurHistory = () => (
  <OurHistoryContainer>
    <div>
      <Title>A nossa história...</Title>
      <Description>
        <HistoryText />
      </Description>

      <KnowMoreButton to="/historia">Saber Mais</KnowMoreButton>
    </div>
    <div>
      <Img src={image} alt="A nossa história" />
    </div>
  </OurHistoryContainer>
);

export default OurHistory;
